import React, { FC, SelectHTMLAttributes, useContext } from 'react'
import { Option } from '../__index'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'

type SelectFieldProps = {
	label?: string
	options?: Option[]
	attribute?: SelectHTMLAttributes<HTMLSelectElement>
}

const SelectField: FC<SelectFieldProps> = ({ options, attribute, label }) => {
	const { pageData } = useContext<AppContextProps>(AppStateContext)
	return (
		<div>
			{label && <label>{pageData.assets[label] ?? label}</label>}
			<select {...attribute}>
				{options?.map((option, index) => {
					return (
						<option {...option._attributes} key={`${option._text}-${index}`}>
							{pageData.assets[option._text] ?? option._text}
						</option>
					)
				})}
			</select>
		</div>
	)
}

export default SelectField
